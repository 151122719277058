/**
 * @desc 人脸图库
 * @author wwj
 * @modify tlzj
 */

/**
 * @desc state 参数
 * @param {Array} list 列表数据
 * @param {Boolean} loading 加载状态
 * @param {String} size 图片查看状态  default: normal normal(默认) small(小)  large(大)
 * @param {Boolean} isSearch 是否是以图搜图
 * @param {Array} checkedIds 生成轨迹选中的id
 * @param {Array} this.searchType 查询类型 0: 以图搜图 1： 上传图片进行搜图 2 特征值搜图
 * @param {Boolean} this.initTime 初始进入页面时先出现loading,不出现noData组件
 * @param {Object} this.urlParams 地址栏参数
 * @param {String} frameUrl 原始大图  没有用小图代替
 * @param {Array} rects 结构化信息数组
 * @param {String} url 框选Url 左上角小图
 */

/**
 * tips:
 * 1.上传图片搜图--上传图片获取url/base64 ---> 根据url/base64获取特征值 ---> 根据特征值获取列表
 * 2.关联搜图--使用id获取特征值
 * 3.特征值搜图
 */

import React from 'react'
import { message } from 'antd'
import { toJS } from 'mobx'
import Search from './search'
import { withRouter } from 'react-router-dom'
import ListWithTab from './ListWithTab'
import SearchMapModal from './SearchMapModal/index.js'
import RightHeader from './RightHeader'
import _ from 'lodash'

const Wrapper = Loader.loadBusinessComponent('BaseLibComponents', 'Wrapper')
const TitleOptions = Loader.loadBusinessComponent('BaseLibComponents', 'TitleOptions')
const Content = Loader.loadBusinessComponent('ResourceComponents', 'Content')
const IconFont = Loader.loadBaseComponent('IconFont')
const Loading = Loader.Loading

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('face', 'tab', 'user')
@Decorator.businessObserver({ face: ['searchData'], user: ['userHabit'] })
class FaceLibrary extends React.Component {
  constructor(props) {
    super(props)
    this.initTime = true
    this.urlParams = Utils.queryFormat(props.location.search)
    this.urlParams.isSearch = this.urlParams.isSearch === 'true' ? true : false
    this.indexDBResult = {}
    this.refContent = React.createRef()
    this.state = {
      list: [],
      checkedIds: [],
      loading: true,
      size: props.user.userHabit.faceModel || 'normal',
      key: Math.random(),
      modalkey: Math.random(),
      imgVisible: false, // 框选模态框
      url: '',
      frameUrl: '', // 模态框Url
      isSearch: false,
      rects: [], //结构化信息
      aidImageList: [], // 右侧aid列表
      isAidSearch: false, // aid 搜图
      activeKey: 1
    }
  }
  componentDidMount() {
    this.suffix = this.props.location.pathname.split('/')[1]
    try {
      this.initUrlOptions()
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const faceModel = nextProps.user.userHabit.faceModel
    if (faceModel && faceModel !== prevState.size && nextProps.storeId === BaseStore.tab.currentId) {
      return { size: nextProps.user.userHabit.faceModel }
    }
    return null
  }

  /**
   * @desc 判断是否是上传图片搜图
   */
  getPictureBySearch = () => {
    return parseInt(this.urlParams.searchType, 10) === 1
  }

  /**
   * @desc 进行图片结构化
   */
  getFearture = url => {
    let isBase64 = false
    if (url && url.indexOf('data:image/png;base64') >= 0) {
      url = url.split(',')[1]
      isBase64 = true
    }
    return Service.face.getFeature({
      [isBase64 ? 'base64' : 'url']: url
    })
  }

  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = async () => {
    const { searchData } = this.props.face
    if (!this.urlParams.id) {
      return
    }
    const result = await LM_DB.get('parameter', this.urlParams.id)

    if (!result) {
      return
    }
    this.indexDBResult = result
    const options = {
      url: result.url,
      frameUrl: result.frameUrl,
      isSearch: true
    }

    let featureList = []
    try {
      featureList = await this.getParmsFeature(result)
    } catch (e) {
      this.setState({ loading: false })
      return message.warn('提取特征值失败！')
    }
    // TODO 特征值不存在
    if (!Array.isArray(featureList) || featureList.length === 0) {
      return this.mergeSearchData({
        ...Utils.getTimerArea(searchData.timerTabsActive),
        ...result.searchData,
        imgId: result.imgId,
        id: result.smId
      })
    }
    options.rects = featureList

    //TODO 单个特征时处理逻辑
    if (featureList.length === 1) {
      const mergeParams = {
        ...Utils.getTimerArea(searchData.timerTabsActive),
        ...result.searchData,
        feature: featureList[0].feature,
        imgId: result.imgId,
        id: result.smId
      }
      this.setState({ ...options })
      return this.mergeSearchData(mergeParams, true)
    }
    this.mergeSearchData({ ...Utils.getTimerArea(searchData.timerTabsActive), ...result.searchData, imgId: result.imgId }, false)
    console.log(result)
    if (result.defaultRect && result.defaultRect.feature) {
      this.onClickDefaultRect(result.defaultRect, result.url)
    } else {
      options.imgVisible = true
    }
    this.setState({ ...options })
  }

  getParmsFeature = async (parms = {}) => {
    if (parms.rects) {
      return parms.rects.filter(v => v.type === 'face')
    }
    if (parms.feature) {
      return [
        {
          feature: parms.feature,
          type: 'face',
          value: parms.data.faceRect
        }
      ]
    }
    if (parms.smId) {
      const result = await Service.face.faces({ id: parms.smId })
      return result.data
        ? [
            {
              feature: result.data.faceFeature,
              type: 'face',
              value: result.data.faceRect
            }
          ]
        : []
    }

    if (parms.url) {
      const result = await this.getFearture(parms.url)
      const list = (result.data && result.data.list) || []
      return list.map(v => ({
        feature: v.feature,
        type: 'face',
        value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
      }))
    }
  }

  /**
   * @desc 根据id获取详情
   */
  getDataDetail = () => {
    return Service.face.faces({ id: this.urlParams.id }).then(res => {
      this.setState({ data: res.data })
      return res
    })
  }

  /**
   * @desc 以图搜图查询判断-上传图片搜图和关联搜图处理
   */
  handleOptionsByType = options => {
    const { searchData } = this.props.face
    const searchType = this.urlParams.searchType
    options.offset = searchData.offset
    options.feature = searchData.feature
    if (searchType === '0') {
      options.id = this.urlParams.id
    }
    // options.score = searchData.score;
    return options
  }

  /**
   * @desc 查询
   */
  search = async (isLoadMore = false) => {
    const { searchData } = this.props.face
    let options = Utils.faceOptions(searchData)
    /**列表查询 */
    !isLoadMore && this.setState({ loading: true })
    console.log(options)
    try {
      if (this.state.activeKey === 1) {
        const results = await Service.face.queryFacesByFeature(options, {
          url: this.state.url,
          searchType: '上传'
        })
        if (results && results.data) {
          return this.handleResult(results.data.list || [], isLoadMore)
        }
      } else {
        const { aids, startTime, endTime, score, feature, offset, limit } = options
        const results = await Service.person.getTogetherByFeature({ aids, startTime, endTime, score, feature, offset, limit })
        const list = results.data.list.map(v => ({ ...v.accompanyCaptureInfos[0], id: Utils.uuid() }))
        results.data.list = list
        if (results && results.data) {
          return this.handleResult(results.data.list || [], isLoadMore)
        }
      }
    } catch (err) {
      console.error(err)
      this.initTime = false
      this.setState({
        loading: false
      })
      message.warning(err.data.message)
    }
  }

  /**
   * @desc 查询列表成功之后的回调
   * @param {Object} results 请求到的数据
   * @param {Boolean} isLoadMore 加载下一页
   */
  handleResult = (dataList, isLoadMore) => {
    this.initTime = false
    let list = isLoadMore ? this.state.list : []
    list = list.concat(dataList)
    this.setState({
      list,
      loading: false,
      checkedIds: !isLoadMore ? [] : this.state.checkedIds
    })
    return dataList.length
  }

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   */
  mergeSearchData = async (options, needSearch = true, needReplace = false) => {
    const { frameUrl } = this.state
    const params = Object.assign({}, options, needSearch ? { minId: null, offset: 0 } : {})
    await this.props.face.mergeSearchData(params)
    if (needReplace) {
      // 对应三种情况--1.普通列表 2.以图搜图 3.上传图片
      const id = !options.id ? Utils.uuid() : options.id
      const { searchData, ...params } = this.indexDBResult
      const newSearchData = Object.assign({}, searchData, toJS(this.props.face.searchData))
      const pageData = Object.assign({}, this.urlParams, { id }) // 跳转url参数
      const indexDBObj = Object.assign({}, params, { searchData: newSearchData, id, frameUrl, rects: newSearchData.reacts })
      this.changeIndexDBData(indexDBObj, pageData)
      this.forceUpdatePositon()
      this.forceUpdateStatus()
    }
    needSearch && this.search()
  }

  /**
   * @desc 缓存数据，更新url
   * @parma {object} indexDBObj 存入indexDB的数据
   * @parma {object} urlParams url参数修改
   */
  changeIndexDBData = async indexDBObj => {
    return
  }

  /**
   * @desc 刷新
   */
  Refresh = () => {
    this.forceUpdateStatus()
    this.forceUpdatePositon()
    const { searchData } = this.props.face
    const options = searchData.timerTabsActive == 2 ? {} : Utils.getTimerArea(searchData.timerTabsActive)
    this.mergeSearchData({ minId: null, offset: 0, ...options })
  }

  /**
   * @desc 重置
   */
  reset = () => {
    this.forceUpdateStatus()
    this.forceUpdatePositon()
    this.initPageData()
  }

  /**
   * @desc 勾选
   */
  onChecked = checkedIds => {
    this.setState({ checkedIds }, this.forceUpdateList())
  }

  /**
   * @desc 滚动加载
   */
  loadMore = () => {
    const { list } = this.state
    let { offset, limit } = this.props.face.searchData
    let searchDataObj = this.urlParams.isSearch
      ? {
          offset: offset + limit // 以图搜图
        }
      : {
          minId: list[list.length - 1].id // 图库列表
        }
    return this.mergeSearchData(searchDataObj, false).then(() => this.search(true))
  }
  forceUpdateStatus() {
    try {
      this.refContent.current && this.refContent.current.setState({ hasLoadMore: true })
    } catch (e) {}
  }
  forceUpdateList() {
    try {
      setTimeout(() => this.refContent.current && this.refContent.current.infiniteRef.current.forceUpdateGrid(), 10)
    } catch (e) {}
  }
  forceUpdatePositon(top = 0) {
    try {
      setTimeout(() => this.refContent.current && this.refContent.current.infiniteRef.current.scrollToPosition(top), 10)
    } catch (e) {}
  }

  /**
   * @desc 切换图片显示大小
   */
  changesize = size => {
    const { user } = this.props
    this.setState({ size })
    user.setUserHabit({ faceModel: size })
  }

  /**
   * @desc 重置所有查询条件 - 重置url
   */
  initPageData = async () => {
    const { face } = this.props
    face.initData()
    this.initUrlOptions()
  }

  closeModal = () => {
    this.setState({
      imgVisible: false,
      loading: false
    })
  }

  /**
   * @desc 以图搜图多个特征值点击框选搜图
   * @param {Bollean} type 框选模态框显隐
   * @param {string} url 图片Url
   * @param {rect} 结构化信息
   */
  handFrame = async (url, rects, type, option, imageUrl) => {
    // 新版图库 重新上传图片 删除aids 以及右侧aids列表

    const id = Utils.uuid()
    let indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.face.searchData), url, id })
    let pageData = Object.assign({}, this.urlParams, { id, isSearch: true, searchType: 1 }) // 跳转url参数
    this.changeIndexDBData(indexDBObj, pageData)
    this.setState({
      url: url,
      frameUrl: imageUrl,
      rects: rects,
      imgVisible: type,
      modalkey: Math.random(),
      isSearch: true
    })
    if (option) {
      this.onClickDefaultRect({ ...option, imgId: undefined }, url)
    }
  }

  /**
   * @desc 以图搜图多个特征值点击默认框搜图
   */
  onClickDefaultRect = async (parms = {}, url, otherOptions = {}) => {
    const { searchData } = this.props.face
    const { frameUrl } = this.state
    this.urlParams.isSearch = true

    const option = Object.assign({}, searchData, {
      feature: parms.feature,
      id: parms.smId,
      ...otherOptions
    })
    console.log(option)
    this.mergeSearchData(option, true, true)
    this.setState({
      url,
      imgVisible: false,
      frameUrl,
      loading: true
    })
  }

  /**
   * @desc 人脸搜图详情跳转
   */
  goPage = id => {
    const { tab, face } = this.props
    tab.openDetailPage({
      moduleName: 'resourceSearchDetail',
      value: id,
      data: { id },
      beforeAction: async () => {
        const searchData = {
          ...Utils.faceOptions(toJS(face.searchData)),
          timerTabsActive: face.searchData.timerTabsActive
        }
        await LM_DB.add('parameter', { list: this.state.list, searchData, url: this.state.url, id, type: 'face' })
      }
    })
  }
  changeMenuActive = type => {
    this.setState({ activeKey: type, list: [] }, () => this.search())
  }
  onClickTXItem = async item => {
    const { tab, face } = this.props
    const id = Utils.uuid()
    tab.openDetailPage({
      moduleName: 'epidemicPersonSearchDetail',
      value: id,
      data: { id },
      beforeAction: async () => {
        const searchData = {
          ...Utils.faceOptions(toJS(face.searchData)),
          timerTabsActive: face.searchData.timerTabsActive
        }
        await LM_DB.add('parameter', { list: this.state.list, searchData, url: this.state.url, id, currentItem: item })
      }
    })
  }
  render() {
    const {
      list,
      loading,
      size,
      url,
      key,
      checkedIds,
      imgVisible,
      rects = [],
      frameUrl,
      modalkey,
      isSearch,
      isAidSearch,
      aidImageList,
      activeKey
    } = this.state
    const { searchData } = this.props.face
    let listDataIds = []
    list.map(v => {
      if (v.latitude && v.longitude) {
        listDataIds.push(v.id)
      }
      return v
    })
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="face-wrapper face-new-wrapper epidemic-person-search-list-wrapper" reset={this.reset} title="接触人员排查">
          <Search
            mergeSearchData={(values, needSearch, isScoreChange) => this.mergeSearchData(values, needSearch, true, isScoreChange)}
            searchData={searchData}
            url={url}
            frameUrl={frameUrl}
            key={key}
            isSearch={isSearch}
            changeUrl={this.reset}
            handFrame={this.handFrame}
            rects={rects}
            isAidSearch={isAidSearch}
            aidImageList={aidImageList}
          />
          {activeKey === 1 ? (
            <Content
              list={list}
              limit={searchData.limit}
              ref={this.refContent}
              onChecked={this.onChecked}
              loadMore={this.loadMore}
              size={size}
              searchData={searchData}
              isSearch={isSearch}
              goPage={this.goPage}
              type="face"
              checkedIds={checkedIds}
              initTime={this.initTime}
            />
          ) : (
            <ListWithTab list={list} size={size} onClickItem={this.onClickTXItem} />
          )}
          <div className="header-little-pagtion">
            <div className="header-menu-wrapper">
              <div className={`header-menu-item ${activeKey === 1 ? 'header-menu-item-active' : ''}`} onClick={() => this.changeMenuActive(1)}>
                <IconFont type="icon-S_Bar_WearMask" />
                人员活动记录
              </div>
              <div className={`header-menu-item ${activeKey === 2 ? 'header-menu-item-active' : ''}`} onClick={() => this.changeMenuActive(2)}>
                <IconFont type="icon-L_AID_People" />
                同出现人员
              </div>
            </div>
            <TitleOptions onChange={this.changesize} value={size} Refresh={this.Refresh} />
            {isSearch && (
              <RightHeader
                type={activeKey}
                list={list}
                suffix={this.suffix}
                listDataIds={listDataIds}
                onChecked={this.onChecked}
                checkedIds={checkedIds}
              />
            )}
          </div>
        </Wrapper>
        <SearchMapModal
          className="img-cut-modal"
          visible={imgVisible}
          width="960px"
          title="以图搜图"
          url={frameUrl}
          key={modalkey}
          rects={rects}
          type="face"
          otherModalFooter={true}
          onCancel={this.closeModal}
          onClickDefaultRect={this.onClickDefaultRect}
        />
      </Loading>
    )
  }
}

export default FaceLibrary
