import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import SelectIMGPicture from '../SelectIMGPicture'
import { message } from 'antd'
import './index.less'

const ModalComponent = Loader.loadBaseComponent('ModalComponent')

@withRouter
@Decorator.businessProvider('tab')
class SearchMapModal extends Component {
  onClickSelectRect = async imagePath => {
    const { tab, type = 'face', onCancel } = this.props

    const res = await Service.face.getFeature({ base64: imagePath.replace('data:image/png;base64,', '') }).catch(() => ({ data: { list: [] } }))
    const featureList = res.data.list || []
    if (featureList.length === 0) {
      return message.warn('未获取到特征！')
    }
    const arr = featureList.map(v => ({ type: 'face', feature: v.feature, value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}` }))
    onCancel && onCancel()
    const item = arr[0]
    const id = Utils.uuid()
    await LM_DB.add('parameter', {
      id,
      frameUrl: imagePath,
      url: imagePath,
      feature: featureList.length === 1 ? item.feature : undefined,
      rects: arr,
      defaultRect: featureList.length === 1 ? item : undefined
    })
    BaseStore.tab.goPage({ moduleName: 'epidemicPersonSearchList', data: { id, isSearch: true } })
  }

  render() {
    const { url, rects, onClickDefaultRect } = this.props
    return (
      <ModalComponent {...this.props} className="search-map-modal">
        <SelectIMGPicture
          lazyLoad={true}
          src={url}
          rects={rects}
          onClickDefaultRect={onClickDefaultRect}
          onClickSelectRect={this.onClickSelectRect}
        />
      </ModalComponent>
    )
  }
}

export default SearchMapModal
