import React from 'react'

const { Loader, Dict, Utils } = window

const { itemHeight, itemSearchHeight, itemWidth, pdWidth } = Dict.map.baselibOptions
const InfiniteScrollLayout = Loader.loadBaseComponent('ReactVirtualized', 'InfiniteScrollLayout')
const ImageView = Loader.loadBaseComponent('ImageView')
const IconFont = Loader.loadBaseComponent('IconFont')

const url =
  'http://imagetest3.lingda.com:8099/image/v1/753383165/objects/5e670bf22ce7b6fd1010344c/1583856000.jpg?signature=1f67f9141d523547ab1c486e3d8dcfb7&crop=x_588,y_364,w_153,h_195&watermark=m_E5BA94E794A8E7B3BBE7BB9FE7AEA1E79086E59198&expires=1583856000'
export default function List({ size, list, onClickItem }) {
  return (
    <div className="baselib-list-wrapper">
      <InfiniteScrollLayout
        pdWidth={pdWidth[size]}
        itemWidth={300}
        itemHeight={258}
        data={list}
        loadMore={false}
        hasLoadMore={false}
        hasBackTop={true}
        renderItem={(v, index) => <Card {...v} onClick={() => onClickItem(v)} />}
      />
    </div>
  )
}

function Card({ aid1FaceUrl, aid2FaceUrl, deviceName, aid1CaptureTime, onClick }) {
  return (
    <div className="person-track-card" onClick={onClick}>
      <div className="image-box">
        <div className="image-item">
          <ImageView src={aid1FaceUrl}></ImageView>
          <span>查找人员</span>
        </div>
        <div className="image-item">
          <ImageView src={aid2FaceUrl}></ImageView>
          <span>同出现人员</span>
        </div>
      </div>
      <div className="item-info">
        <div>
          <IconFont type="icon-S_Bar_Add" />
          {deviceName}
        </div>
        <div>
          <IconFont type="icon-S_Edit_ClockEnd" />
          {Utils.formatTimeStamp(aid1CaptureTime)}
        </div>
      </div>
    </div>
  )
}
